import { IUserState } from "redux/models/UserState";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "interfaces/User";
import { IProfile } from "interfaces/Profile";
import { IBlockchainData } from "interfaces/Blockchain";
import { IRaces } from "interfaces/Race";
import { IContracts } from "interfaces/Contracts";
import { fetchProfileData } from "redux/asyncActions/fetchProfileData";
import { fetchBlockchainData } from "redux/asyncActions/fetchBlockChainData";

const initialState: IUserState = {
  abi: null,
  blockchainData: null,
  profile: null,
  races: null,
  user: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<IUser | null>) {
      state.user = action.payload;
    },

    setProfile(state, action: PayloadAction<IProfile | null>) {
      state.profile = action.payload;
    },

    setBlockchainData(state, action: PayloadAction<IBlockchainData | null>) {
      state.blockchainData = action.payload;
    },

    setAbi(state, action: PayloadAction<IContracts | null>) {
      state.abi = action.payload;
    },

    setRaces(state, action: PayloadAction<IRaces | null>) {
      state.races = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchProfileData.fulfilled, (state, action) => {
      state.profile = action.payload;
    });

    builder.addCase(fetchBlockchainData.fulfilled, (state, action) => {
      state.abi = action.payload.contracts;
      state.blockchainData = action.payload.user;
      state.races = action.payload.races;
    });
  },
});

export const { setUser, setAbi, setBlockchainData, setProfile, setRaces } =
  userSlice.actions;

export default userSlice.reducer;
