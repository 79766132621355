import React, { useState, useEffect } from "react";
import styles from "styles/components/popup/connect-wallet.module.scss";
import PopupClose from "components/PopupClose";
import PrimaryButton from "components/UI/PrimaryButton";
import { useConnect } from "wagmi";
import Alert from "utils/alert";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import { selectUser } from "redux/selectors/userSelector";
import { setIsOpen } from "redux/reducers/PopupSlice";

type walletKeys = "MM" | "WC";

interface IWalletItem {
  icon: string;
  label: string;
  key: walletKeys;
}

const walletList: IWalletItem[] = [
  {
    icon: "/img/wallets/metamask.png",
    label: "Metamask",
    key: "MM",
  },

  {
    icon: "/img/wallets/walletconnect.png",
    label: "WalletConnect",
    key: "WC",
  },
];

const ConnectWallet: React.FC = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectUser);
  const { connectors, connect, isError, error } = useConnect();

  const metamask = connectors[0];
  const walletConnect = connectors[1];

  const [selectedWallet, setSelectedWallet] = useState<walletKeys>();

  const handleSelect = (key: walletKeys) => {
    setSelectedWallet(key);
  };

  const handleConnect = async () => {
    switch (selectedWallet) {
      case "WC": {
        connect({ connector: walletConnect });
        break;
      }
      case "MM": {
        connect({ connector: metamask });
        break;
      }
    }
  };

  useEffect(() => {
    if (user) {
      dispatch(setIsOpen(false));
    }
  }, [user]);

  useEffect(() => {
    if (isError) {
      if (error?.name === "ConnectorNotFoundError") {
        Alert("error", "Wallet is not installed in your browser");
      }
    }
  }, [isError]);

  return (
    <div className={styles.connect_wallet} onClick={(e) => e.stopPropagation()}>
      <PopupClose />

      <h5 className={styles.connect_wallet__title}>
        Connect a wallet to continue
      </h5>

      <p className={styles.connect_wallet__text}>
        Choose how you want to connect. If you don't have a wallet, you can
        select a provider and create one.
      </p>

      <ul className={styles.connect_wallet__app_list}>
        {walletList.map((item) => (
          <li
            onClick={() => handleSelect(item.key)}
            key={item.key}
            className={`${styles.connect_wallet__app} ${
              selectedWallet === item.key
                ? styles["connect_wallet__app--active"]
                : ""
            }`}
          >
            <div className={styles.connect_wallet__label}>
              <div className={styles.connect_wallet__icon}>
                <img src={item.icon} alt="Wallet icon" />
              </div>
              <span>{item.label}</span>
            </div>

            <div className={styles.connect_wallet__round}>
              <span></span>
            </div>
          </li>
        ))}
      </ul>

      <div className={styles.connect_wallet__button}>
        <PrimaryButton
          label="Connect"
          small
          callback={handleConnect}
          disabled={selectedWallet === undefined}
        />
      </div>
    </div>
  );
};

export default ConnectWallet;
