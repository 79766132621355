import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import { selectPopup } from "redux/selectors/popupSelector";
import styles from "styles/components/popup.module.scss";
import { setIsOpen } from "redux/reducers/PopupSlice";

const Popup: React.FC = () => {
  const dispatch = useAppDispatch();
  const { content, isOpen } = useAppSelector(selectPopup);

  const handleClose = () => dispatch(setIsOpen(false));

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("lock");
    } else {
      document.body.classList.remove("lock");
    }
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div className={styles.popup} onClick={handleClose}>
          <div className={styles.popup__content}>{content}</div>
        </div>
      )}
    </>
  );
};

export default Popup;
