import { IUserResponse } from "interfaces/User";
import { UserApi } from "utils/api/User";
import Alert from "./alert";

export const connectWagmiWallet = async (address: `0x${string}`) => {
  const betaWallet = localStorage.getItem("beta_account");
  // Remove on production
  if (address !== betaWallet && betaWallet !== "common") {
    Alert("error", "Wallet is not agreed, Write to our community manager");
    return null;
  }

  const user: IUserResponse | null = await UserApi.login({
    wallet: address,
  });

  if (user) {
    if (!localStorage.getItem("wallet")) {
      Alert("success", "Successfully connected");
    }

    localStorage.setItem("wallet", address);

    return user;
  }

  return null;
};
