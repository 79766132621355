import React, { useState, useEffect, useCallback, useRef, memo } from "react";
import { callbacks } from "common/bridge";
import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import useDebounce from "utils/useDebounce";
import { RaceApi } from "utils/api/Race";
import { useAppSelector } from "redux/hooks/redux";
import { selectUser } from "redux/selectors/userSelector";
import { transactionSuccess } from "utils/transactionSucess";
import { transactinError } from "utils/transactionError";

const PrimaryRace: React.FC = memo(() => {
  const { abi, races } = useAppSelector(selectUser);

  const [ids, setIds] = useState<number[]>([]);

  const debouncedIds = useDebounce(ids, 0);

  // Contract request recreates twice. this ref created to send only one contract
  const preventContractDuplicate = useRef<boolean>(false);

  const {
    config,
    isError: isConfigError,
    error: configError,
  } = usePrepareContractWrite({
    abi: abi!.raceQualifications.abi,
    address: abi!.raceQualifications.address,
    functionName: "joinToRace",
    args: [debouncedIds],
    enabled: debouncedIds.length > 0,
  });

  const { write, data, isError, reset } = useContractWrite(config);

  const { isSuccess, isError: isTransactionError } = useWaitForTransaction({
    hash: data?.hash,
    confirmations: 3,
  });

  const handleJoin = useCallback((_ids: number[]) => {
    setIds(_ids);
  }, []);

  const handlePrimaryRace = () => write?.();

  useEffect(() => {
    if (isSuccess) {
      const apiCall = () => {
        RaceApi.joinToRace({
          race_id: races?.qualification.current.id!,
          cars: debouncedIds,
        });
      };

      transactionSuccess({
        apiCall,
        setState: setIds,
        type: "array",
      });
    }
  }, [isSuccess]);

  // wainig for update of contract arguments important!!!
  useEffect(() => {
    if (config.request && debouncedIds && preventContractDuplicate.current) {
      handlePrimaryRace();
      preventContractDuplicate.current = false;
    } else {
      preventContractDuplicate.current = true;
    }
  }, [config.request]);

  useEffect(() => {
    transactinError({
      configError,
      isConfigError,
      isError,
      isTransactionError,
      reset,
    });
  }, [isError, isTransactionError, isConfigError]);

  callbacks.add("onJoinRacePrimary", handleJoin);

  return <></>;
});

export default PrimaryRace;
