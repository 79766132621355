import React from "react";

const Logo: React.FC = () => {
  return (
    <svg
      width={72}
      height={75}
      viewBox="0 0 72 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.7783 38.065C56.3521 38.065 59.2483 40.8507 59.2483 44.2882C59.2483 47.7256 56.3521 50.5113 52.7783 50.5113C49.2044 50.5113 46.3082 47.7256 46.3082 44.2882C46.3082 40.8507 49.2044 38.065 52.7783 38.065ZM52.7783 41.0535C54.6369 41.0535 56.1412 42.5032 56.1412 44.2882C56.1412 46.0758 54.6341 47.5228 52.7783 47.5228C50.9196 47.5228 49.4153 46.0731 49.4153 44.2882C49.4153 42.5005 50.9224 41.0535 52.7783 41.0535ZM36.0028 0.885773C16.2806 0.885773 0 16.5477 0 35.5146V55.823C5.25533 55.8527 10.0242 57.8541 13.5503 61.0941L13.5671 53.8703C10.1058 51.9744 7.77193 48.3936 7.77193 44.2909C7.77193 38.2057 12.9007 33.2726 19.2274 33.2726C25.554 33.2726 30.6828 38.2057 30.6828 44.2909C30.6828 50.2516 25.7621 55.1036 19.6154 55.3037L19.5732 72.94C19.6098 73.2916 19.6379 73.6459 19.6548 74.0029H26.167V69.5188C26.167 68.2666 27.2327 67.2415 28.5346 67.2415C29.8364 67.2415 30.9021 68.2666 30.9021 69.5188V74.0029H41.0951V69.5188C41.0951 68.2666 42.1607 67.2415 43.4626 67.2415C44.7645 67.2415 45.8302 68.2666 45.8302 69.5188V74.0029H52.3424C52.3509 73.8027 52.3649 73.6053 52.3818 73.4079L52.424 55.3037C46.2576 55.1279 41.3144 50.2652 41.3144 44.2909C41.3144 38.2057 46.4432 33.2726 52.7698 33.2726C54.8703 33.2726 56.8386 33.8162 58.5313 34.7655L63.1202 30.3517C64.2984 29.2185 66.2245 29.2185 67.4026 30.3517C68.5808 31.4849 68.5808 33.3375 67.4026 34.4707L62.7744 38.9224C63.6966 40.5099 64.2253 42.3409 64.2253 44.2909C64.2253 48.3747 61.9139 51.942 58.4807 53.8433L58.4638 61.0806C61.9898 57.8487 66.7503 55.8554 72 55.823V35.5146C72 16.545 55.7166 0.885773 35.9944 0.885773H36.0028Z"
        fill="#EB4B41"
      />
    </svg>
  );
};

export default Logo;
