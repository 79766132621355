import Sidebar from "components/Sidebar";
import React, { useState, useEffect } from "react";
import Client from "components/Client";
import { publicProvider } from "wagmi/providers/public";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { mainnet, bscTestnet } from "wagmi/chains";
import "react-toastify/dist/ReactToastify.css";
import Popup from "components/Popup";
import { alchemyProvider } from "wagmi/providers/alchemy";
import FlipDevice from "components/FlipDevice";
import Layout from "components/Layout";
import { Provider } from "react-redux";
import { setupStore } from "redux/store";
import BetaLogin from "components/BetaLogin";

const projectId: string = process.env.REACT_APP_PROJECT_ID!;
const alchemyKey: string = process.env.REACT_APP_ALCHEMY_KEY!;

export const { chains, publicClient, webSocketPublicClient } = configureChains(
  [process.env.REACT_APP_MODE === "development" ? bscTestnet : mainnet],
  [alchemyProvider({ apiKey: alchemyKey }), publicProvider()]
);

const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId,
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
});

const store = setupStore();

const App: React.FC = () => {
  const [logged, setLogged] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("beta_account")) {
      setLogged(true);
    }
  }, [localStorage.getItem("beta_account")]);

  return (
    <WagmiConfig config={config}>
      <Provider store={store}>
        <Layout>
          {logged ? (
            <>
              <Sidebar />

              <Client />

              <Popup />

              <FlipDevice />
            </>
          ) : (
            <BetaLogin setLogged={setLogged} />
          )}
        </Layout>
      </Provider>
    </WagmiConfig>
  );
};

export default App;
