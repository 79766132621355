import React from "react";

const Rhombus: React.FC = () => {
  return (
    <svg
      width={21}
      height={21}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ position: "relative", bottom: "0.3rem" }}
    >
      <g clipPath="url(#clip0_74_2)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 7.36107L7.36107 0H13.6273L20.9884 7.36107V13.6273L13.6273 20.9884H7.36107L0 13.6273L0 7.36107ZM3.92512 10.4942L10.4942 3.92512L17.0632 10.4942L10.4942 17.0632L3.92512 10.4942Z"
          fill="url(#paint0_linear_74_2)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_74_2"
          x1="10.4942"
          y1={0}
          x2="10.4942"
          y2="20.9884"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7854" />
          <stop offset={1} stopColor="#E73B4B" />
        </linearGradient>
        <clipPath id="clip0_74_2">
          <rect width={21} height={21} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Rhombus;
