// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".custom-scrollbar_custom_scrollbar__98ZlK::-webkit-scrollbar {\n  width: 0.7rem;\n}\n.custom-scrollbar_custom_scrollbar__98ZlK::-webkit-scrollbar-track {\n  background: #504153;\n  border-radius: 1rem;\n}\n.custom-scrollbar_custom_scrollbar__98ZlK::-webkit-scrollbar-thumb {\n  background: #eb4a41;\n  border-radius: 1rem;\n}", "",{"version":3,"sources":["webpack://./src/styles/components/custom-scrollbar.module.scss","webpack://./src/styles/abstracts/variables.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;AAFJ;AAKE;EACE,mBCEQ;EDDR,mBAAA;AAHJ;AAME;EACE,mBCPU;EDQV,mBAAA;AAJJ","sourcesContent":["@import \"../abstracts/variables.scss\";\n\n.custom_scrollbar {\n  &::-webkit-scrollbar {\n    width: 0.7rem;\n  }\n\n  &::-webkit-scrollbar-track {\n    background: $graycolor;\n    border-radius: 1rem;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background: $orangecolor;\n    border-radius: 1rem;\n  }\n}\n","// Default font //\n$fontfamily: \"Roboto Condensed\";\n\n// Colors //\n$whitecolor: #ffffff;\n$blackcolor: #000000;\n$orangecolor: #eb4a41;\n$darkpurplecolor: #74566c;\n$darkcolor: #180f1a;\n$lightpurplecolor: #442b4a;\n$graycolor: #504153;\n$lightcolor: #eff2f9;\n$darkbluecolor: #010101;\n$urbancolor: #707070;\n$disabledcolor: #cdcdcd;\n$disabledtextcolor: #888888;\n\n$orangegradient: linear-gradient(180deg, #ff7854 0%, #e73b4b 100%);\n\n// Breakpoints //\n\n$minwidth: 320px;\n$mw: 1152;\n$md1: 1599.98px;\n$md2: 1279.98px;\n$md3: 1023.98px;\n$md4: 767.98px;\n$md5: 500px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom_scrollbar": "custom-scrollbar_custom_scrollbar__98ZlK"
};
export default ___CSS_LOADER_EXPORT___;
