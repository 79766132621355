import React from "react";
import Error from "icons/Error";
import Success from "icons/Success";
import { toast } from "react-toastify";

type alertTypes = "success" | "error";

const Alert = (type: alertTypes, message: string) => {
  toast.dismiss();

  switch (type) {
    case "error":
      return toast.error(message, {
        icon: <Error />,
      });
    case "success":
      return toast.success(message, {
        icon: <Success />,
      });
    default:
      return toast.success(message);
  }
};

export default Alert;
