import React from "react";

const MGT: React.FC = () => {
  return (
    <svg
      width={25}
      height={20}
      viewBox="0 0 25 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9.9945L3.0421 9.99418L7.20677 2.711L9.49412 2.71037L5.31744 10.0137L10.9715 20L14.0137 19.9997L8.36355 10.0206L12.5446 2.70911L14.7422 2.70817L10.5945 9.96117L16.2486 19.9475L19.2904 19.9472L19.2753 19.9201H19.3036L25 9.95897L24.9396 9.95866L24.9763 9.89358L20.761 2.44848L19.2398 5.13464L19.2411 5.13715L16.256 10.4095L17.7772 13.0957L20.7623 7.82363L21.9634 9.94514H21.9582L17.772 17.2654L13.6406 9.96809L17.7926 2.70722H17.8238L19.3326 0.0138332L16.2908 0L16.2822 0.0150908L5.69635 0.0188635L0 9.98035L3.0421 9.99418L8.69622 19.9805L5.65411 19.9808L0 9.9945Z"
        fill="#EB4A41"
      />
    </svg>
  );
};

export default MGT;
