import React, { useState } from "react";
import styles from "styles/components/beta-login.module.scss";
import { UserApi } from "utils/api/User";
import PrimaryButton from "./UI/PrimaryButton";
import Logo from "icons/Logo";

interface BetaLoginProps {
  setLogged: React.Dispatch<React.SetStateAction<boolean>>;
}

const BetaLogin: React.FC<BetaLoginProps> = ({ setLogged }) => {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");

  const [dataSending, setDataSending] = useState(false);

  const handleChange = (
    value: string,
    setValue: React.Dispatch<React.SetStateAction<string>>
  ) => {
    setValue(value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDataSending(true);

    UserApi.betaLogin({ user, password }, setLogged).finally(() =>
      setDataSending(false)
    );
  };

  return (
    <div className={styles.beta_login}>
      <Logo />
      <form onSubmit={handleSubmit}>
        <input
          placeholder="Username"
          type="text"
          value={user}
          onChange={(e) => handleChange(e.target.value, setUser)}
        />
        <input
          placeholder="Password"
          type="password"
          value={password}
          onChange={(e) => handleChange(e.target.value, setPassword)}
        />

        <PrimaryButton label="Log in" disabled={dataSending} small />
      </form>
    </div>
  );
};

export default BetaLogin;
