// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".overlay_overlay__LPBOA {\n  position: absolute;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(22, 8, 27, 0.8);\n  z-index: 3;\n}", "",{"version":3,"sources":["webpack://./src/styles/components/overlay.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,OAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,sCAAA;EACA,UAAA;AADF","sourcesContent":["@import \"../abstracts/variables.scss\";\n\n.overlay {\n  position: absolute;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgb(22 8 27 / 80%);\n  z-index: 3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": "overlay_overlay__LPBOA"
};
export default ___CSS_LOADER_EXPORT___;
