import { callbacks } from "common/bridge";
import { useCallback } from "react";
import styles from "styles/components/overlay.module.scss";

interface OverlayProps {
  disabled: boolean;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const Overlay: React.FC<OverlayProps> = ({ disabled, setDisabled }) => {
  const onEnable = useCallback((enable: boolean) => {
    setDisabled(!enable);
  }, []);

  callbacks.add("onEnable", onEnable);

  return <>{disabled && <div className={styles.overlay}></div>}</>;
};

export default Overlay;
