import { IGetRaceResponse, IRaces, PrimaryRaceDTO } from "interfaces/Race";
import { authApi as api, API_ROUTES, newAuthApi } from ".";

export const RaceApi = {
  async joinToRace(dto: PrimaryRaceDTO) {
    api
      .post(API_ROUTES.JOIN_RACE, dto)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => console.log(e));
  },

  async getRaceData(dto: number) {
    const response = await newAuthApi.get<IGetRaceResponse>(
      `${API_ROUTES.GET_QUALIFICATIONS_RACE_DATA}${dto}`
    );

    return response.data;
  },
};
