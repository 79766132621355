import React from "react";

const MuteMusic: React.FC = () => {
  return (
    <svg
      width={38}
      height={38}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0791 0H26.9209C33.0009 0 38 4.99911 38 11.0791V26.9209C38 33.0009 33.0009 38 26.9209 38H11.0791C4.99911 38 0 33.0009 0 26.9209V11.0791C0 4.99911 4.99911 0 11.0791 0ZM18.9493 5.53956C19.8276 5.53956 20.5707 6.24889 20.5707 7.16089V30.8729C20.5707 31.7511 19.8613 32.4942 18.9493 32.4942C18.0711 32.4942 17.328 31.7849 17.328 30.8729V7.16089C17.328 6.28267 18.0373 5.53956 18.9493 5.53956ZM12.8356 9.89689C13.7138 9.89689 14.4569 10.64 14.4569 11.5182V24.3538C14.4569 25.232 13.7138 25.9751 12.8356 25.9751C11.9573 25.9751 11.2142 25.2658 11.2142 24.3538V11.5182C11.2142 10.64 11.9236 9.89689 12.8356 9.89689ZM25.0969 12.0587C25.9751 12.0587 26.7182 12.8018 26.7182 13.68V24.3538C26.7182 25.232 25.9751 25.9751 25.0969 25.9751C24.2187 25.9751 23.4756 25.2658 23.4756 24.3538V13.68C23.4756 12.8018 24.1849 12.0587 25.0969 12.0587ZM31.2782 16.4836C32.1564 16.4836 32.8996 17.2267 32.8996 18.1049V22.2258C32.8996 23.104 32.1564 23.8471 31.2782 23.8471C30.4 23.8471 29.6569 23.1378 29.6569 22.2258V18.1049C29.6569 17.2267 30.3662 16.4836 31.2782 16.4836ZM6.78933 14.3556C7.66756 14.3556 8.41067 15.0987 8.41067 15.9769V20.0978C8.41067 20.976 7.66756 21.7191 6.78933 21.7191C5.91111 21.7191 5.168 21.0098 5.168 20.0978V15.9769C5.168 15.0987 5.87733 14.3556 6.78933 14.3556Z"
        fill="white"
      />
    </svg>
  );
};

export default MuteMusic;
