import React, { useEffect, useState, useRef, useCallback, memo } from "react";
import {
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { action, callbacks } from "common/bridge";
import Alert from "utils/alert";
import { NFTApi } from "utils/api/NFT";
import { EventType } from "common/bridge";
import useDebounce from "utils/useDebounce";
import { useAppSelector } from "redux/hooks/redux";
import { selectUser } from "redux/selectors/userSelector";

const OpenCarBox: React.FC = memo(() => {
  const { abi } = useAppSelector(selectUser);
  const { address } = useAccount();

  const [firstOpen, setFirstOpen] = useState(true);
  const [ids, setIds] = useState<number[]>([]);
  const debouncedIds = useDebounce(ids, 0);

  // Contract request recreates twice. this ref created to send only one contract
  const preventDuplicate = useRef(false);
  const preventDuplicateAllowance = useRef(false);

  const { data, refetch: refetchAllowance } = useContractRead({
    abi: abi!.nftCarBox.abi,
    functionName: "isApprovedForAll",
    address: abi!.nftCarBox.address,
    args: [address, abi!.main.address],
  });

  const { config: approveConfig, isError: approveConfigError } =
    usePrepareContractWrite({
      abi: abi!.nftCarBox.abi,
      functionName: "setApprovalForAll",
      address: abi!.nftCarBox.address,
      args: [abi!.main.address, true],
    });

  const {
    config,
    isError: isConfigError,
    error: configError,
  } = usePrepareContractWrite({
    abi: abi!.main.abi,
    functionName: "openCarBox",
    address: abi!.main.address,
    args: [debouncedIds],
    enabled: debouncedIds.length > 0,
  });

  const {
    write: approveWrite,
    data: approveData,
    isError: approveError,
  } = useContractWrite(approveConfig);

  const { write, data: contractData, isError } = useContractWrite(config);

  const { isSuccess: approveIsSuccess, isError: approveTransactionError } =
    useWaitForTransaction({ hash: approveData?.hash });

  const { isSuccess, isError: isTransactionError } = useWaitForTransaction({
    hash: contractData?.hash,
  });

  const handleOpenCarbox = () => {
    write?.();
  };

  //@ts-ignore
  const handleApprove = () => {
    approveWrite?.();
  };

  const onOpen = useCallback((id: number) => {
    setIds([id]);
  }, []);

  // Waitig for update of contract arguments important!!!
  useEffect(() => {
    if (
      config.request &&
      (preventDuplicate.current || firstOpen) &&
      debouncedIds.length > 0 &&
      data
    ) {
      setFirstOpen(false);
      handleOpenCarbox();
    } else if (config.request) {
      preventDuplicate.current = true;
    }
  }, [config.request]);

  // Waitig for update of contract arguments important!!!
  useEffect(() => {
    if (
      approveConfig.request &&
      preventDuplicateAllowance.current &&
      debouncedIds.length > 0 &&
      !data
    ) {
      handleApprove();
      preventDuplicateAllowance.current = false;
    } else if (approveConfig.request) {
      preventDuplicateAllowance.current = true;
    }
  }, [approveConfig.request, debouncedIds]);

  useEffect(() => {
    const successCallback = async () => {
      await NFTApi.openCarbox(ids);
      action.post.purchaseApproved(true);
      action.post.responseEvent(EventType.EVENT_OPEN_CARBOX);
      Alert("success", "Transaction succeed");
      setIds([]);
      setFirstOpen(true);
    };

    if (isSuccess) successCallback();
  }, [isSuccess]);

  useEffect(() => {
    if (approveIsSuccess) {
      refetchAllowance();
      handleOpenCarbox();
    }
  }, [approveIsSuccess]);

  useEffect(() => {
    if (
      !isTransactionError &&
      configError?.message.includes(
        `Encoded error signature "0x59c896be" not found on ABI.`
      ) &&
      !approveTransactionError
    ) {
      return;
    }

    if (
      isError ||
      isTransactionError ||
      isConfigError ||
      approveConfigError ||
      approveTransactionError ||
      approveError
    ) {
      if (
        //@ts-ignore
        configError?.cause?.data?.errorName === "MintZeroQuantity" ||
        //@ts-ignore
        configError?.cause?.data?.args[0] === "Only provider"
      ) {
        action.post.purchaseApproved(false);
        return;
      }

      Alert("error", "Transaction failed");
      action.post.purchaseApproved(false);
    }
  }, [
    isError,
    isTransactionError,
    isConfigError,
    approveConfigError,
    approveTransactionError,
    approveError,
  ]);

  callbacks.add("onOpenCarbox", onOpen);

  return <></>;
});

export default OpenCarBox;
