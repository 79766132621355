import React, { memo, useEffect, useCallback, useState, useRef } from "react";
import Button from "./UI/Button";
import {
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { NFTApi } from "utils/api/NFT";
import { parseEther } from "viem";
import { callbacks, useBridge } from "common/bridge";
import useDebounce from "utils/useDebounce";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import { selectUser } from "redux/selectors/userSelector";
import { transactionSuccess } from "utils/transactionSucess";
import { transactinError } from "utils/transactionError";
import { approveTransactionError } from "utils/approveTransactionError";

const MintEnergy: React.FC = memo(() => {
  const dispatch = useAppDispatch();
  const { abi, blockchainData } = useAppSelector(selectUser);

  const { address } = useAccount();
  const { showChangerUSDTToMGT } = useBridge();

  // Contract request recreates twice. this ref created to send only one contract
  const preventTopupDuplicate = useRef(false);
  const preventAllowanceDuplicate = useRef(false);

  const [amount, setAmount] = useState<number>(0);
  const debouncedAmount = useDebounce<number>(amount, 0);

  const {
    config,
    isError: isConfigError,
    error: configError,
  } = usePrepareContractWrite({
    address: abi!.main.address,
    abi: abi!.main.abi,
    args: [parseEther(`${debouncedAmount}`)],
    functionName: "topupEnergy",
    enabled: Boolean(debouncedAmount),
  });

  const { config: approveConfig, isError: isApproveConfigError } =
    usePrepareContractWrite({
      address: abi!.usdt.address,
      abi: abi!.usdt.abi,
      args: [abi!.main.address, parseEther(`${debouncedAmount}`)],
      functionName: "approve",
      enabled: Boolean(debouncedAmount),
    });

  const { data: allowance, refetch: refetchAllowance } = useContractRead({
    address: `${abi!.usdt.address as `0x${string}`}`,
    abi: abi!.usdt.abi,
    args: [address, abi!.main.address],
    functionName: "allowance",
  });

  const { write, data, isError } = useContractWrite(config);
  const {
    write: approveWrite,
    data: approveData,
    isError: isApproveError,
  } = useContractWrite(approveConfig);

  const {
    isLoading: approveIsLoading,
    isSuccess: approveIsSuccess,
    isError: isApproveTransactionError,
  } = useWaitForTransaction({
    hash: approveData?.hash,
  });

  const {
    isLoading,
    isSuccess,
    isError: isTransactionError,
    data: transactionData,
  } = useWaitForTransaction({
    hash: data?.hash,
  });

  const openSwapper = () => {
    showChangerUSDTToMGT(blockchainData!.balance.usdt);
  };

  const handleApprove = () => {
    approveWrite?.();
  };

  const handleTopup = () => {
    write?.();
  };

  const onBuyEnergy = useCallback((_amount: number) => {
    setAmount(_amount);
  }, []);

  // Waitig for update of contract arguments important!!!
  useEffect(() => {
    const formattedAmount = parseEther(`${debouncedAmount}`);

    if (
      config.request &&
      preventTopupDuplicate.current &&
      (allowance as bigint) >= formattedAmount
    ) {
      handleTopup();
      preventTopupDuplicate.current = false;
    } else {
      preventTopupDuplicate.current = true;
    }
  }, [config.request]);

  useEffect(() => {
    const formattedAmount = parseEther(`${debouncedAmount}`);

    //@ts-ignore
    const isAllowanceError = configError?.shortMessage.includes(
      "ERC20: insufficient allowance"
    );

    if (
      (!isConfigError || isAllowanceError) &&
      approveConfig.request &&
      preventAllowanceDuplicate.current &&
      (allowance as bigint) < formattedAmount
    ) {
      handleApprove();
      preventAllowanceDuplicate.current = false;
    } else {
      preventAllowanceDuplicate.current = true;
    }
  }, [approveConfig.request]);

  useEffect(() => {
    if (isSuccess) {
      const apiCall = () =>
        NFTApi.energyEvent({
          data: transactionData,
          amount: debouncedAmount,
        });

      transactionSuccess({
        setState: setAmount,
        apiCall,
        refetchAllowance,
        dispatch,
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (approveIsSuccess) {
      transactionSuccess({
        setState: setAmount,
        refetchAllowance,
        approve: true,
      });
    }
  }, [approveIsSuccess]);

  useEffect(() => {
    approveTransactionError({
      isApproveConfigError,
      isApproveError,
      isApproveTransactionError,
      setState: setAmount,
    });
  }, [isApproveTransactionError, isApproveError, isApproveConfigError]);

  useEffect(() => {
    transactinError({
      configError,
      isConfigError,
      isError,
      isTransactionError,
      setState: setAmount,
    });
  }, [isError, isTransactionError, isConfigError]);

  callbacks.add("onAcceptChangeUSDTToMGT", onBuyEnergy);
  callbacks.add("onSwapChangeUSDTToMGT", onBuyEnergy);

  return (
    <Button
      lessPadding
      disabled={isLoading || approveIsLoading}
      callback={openSwapper}
      label={isLoading || approveIsLoading ? "Sending..." : "Top up"}
    />
  );
});

export default MintEnergy;
