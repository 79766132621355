import { IEventApiDTO, IAddEnergyDTO } from "interfaces/nft";
import { authApi as api, API_ROUTES } from ".";

const parseToJSON = (dto: any) => {
  return JSON.parse(
    JSON.stringify(dto.data, (key, value) =>
      typeof value === "bigint" ? value.toString() : value
    )
  );
};

export const NFTApi = {
  async createCarBox() {
    const response = await api
      .post<unknown>(`${API_ROUTES.CREATE_CARBOX}`)
      .then(({ data }) => {
        return data;
      });
    return response;
  },

  async createLootBox(dto: IAddEnergyDTO) {
    const response = await api
      .post<unknown>(`${API_ROUTES.CREATE_LOOTBOX}`, dto)
      .then(({ data }) => {
        return data;
      });
    return response;
  },

  async addEnergy(dto: IAddEnergyDTO) {
    const response = await api
      .post<unknown>(`${API_ROUTES.ADD_ENERGY}`, dto)
      .then(({ data }) => {
        return data;
      });
    return response;
  },

  async openCarbox(dto: number[]) {
    const response = await api
      .post<unknown>(`${API_ROUTES.OPEN_CARBOX}`, { ids: dto })
      .then(({ data }) => {
        return data;
      });
    return response;
  },

  async carBoxEvent(dto: IEventApiDTO) {
    const data = parseToJSON(dto);

    const response = await api
      .post<unknown>(`${API_ROUTES.EVENT}`, {
        type: "buyCarbox",
        data,
        amount: dto.amount,
      })
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        console.log(e);
        return null;
      });

    return response;
  },

  async lootBoxEvent(dto: IEventApiDTO) {
    const data = parseToJSON(dto);

    const response = await api
      .post<unknown>(`${API_ROUTES.EVENT}`, {
        type: "buyLootbox",
        data,
        amount: dto.amount,
      })
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        console.log(e);
        return null;
      });

    return response;
  },

  async energyEvent(dto: IEventApiDTO) {
    const data = parseToJSON(dto);

    const response = await api
      .post<unknown>(`${API_ROUTES.EVENT}`, {
        type: "topupEnergy",
        data,
        amount: dto.amount,
      })
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        console.log(e);
        return null;
      });

    return response;
  },

  async withdrawEnergyEvent(dto: IEventApiDTO) {
    const data = parseToJSON(dto);

    const response = await api
      .post<unknown>(`${API_ROUTES.EVENT}`, {
        type: "swapEnergy",
        data,
        amount: dto.amount,
      })
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        console.log(e);
        return null;
      });

    return response;
  },
};
