import React from "react";
import styles from "styles/components/flip-device.module.scss";

const FlipDevice: React.FC = () => {
  return (
    <div className={styles.filp_device}>
      <div className={styles.filp_device__img}>
        <img src="/img/flip.png" alt="Flip your phone" />
      </div>

      <span className={styles.filp_device__label}>
        Flip your device horizontally
      </span>
    </div>
  );
};

export default FlipDevice;
