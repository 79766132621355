import React from "react";
import styles from "styles/components/popup.module.scss";
import Close from "icons/Close";
import { useAppDispatch } from "redux/hooks/redux";
import { setIsOpen } from "redux/reducers/PopupSlice";

interface PopupCloseProps {
  relative?: boolean;
  margin?: boolean;
}

const PopupClose: React.FC<PopupCloseProps> = ({
  relative = false,
  margin = false,
}) => {
  const dispatch = useAppDispatch();

  const handleClose = () => dispatch(setIsOpen(false));

  return (
    <div
      onClick={handleClose}
      className={`${styles.popup__close} ${
        relative ? styles["popup__close--relative"] : ""
      } ${margin ? styles["popup__close--margin"] : ""}`}
    >
      <Close />
    </div>
  );
};

export default PopupClose;
