import React from "react";

const Client: React.FC = () => {
  return (
    <div className="client" style={{ overflow: "hidden" }}>
      <iframe
        id="game"
        src={`${process.env.REACT_APP_GAME_URL}${window.location.search}`}
        title={"canvas"}
        sandbox="allow-same-origin allow-scripts"
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
};

export default Client;
