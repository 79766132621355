import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserApi } from "utils/api/User";

export const fetchBlockchainData = createAsyncThunk(
  "user/fetchBlockchainData",
  async () => {
    const response = UserApi.getData();

    return response;
  }
);
