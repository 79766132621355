import {
  IBetaLoginDto,
  IBetaUserResponse,
  ILoginDto,
  IRefreshResponse,
  IUserResponse,
} from "interfaces/User";
import { IInitResponse, IUserBalance } from "interfaces/Blockchain";
import { api, authApi, API_ROUTES, newAuthApi } from ".";
import Alert from "utils/alert";
import React from "react";

export const UserApi = {
  async betaLogin(
    dto: IBetaLoginDto,
    setState: React.Dispatch<React.SetStateAction<boolean>>
  ) {
    const response = await api
      .post<IBetaUserResponse>(API_ROUTES.BETA_LOGIN, dto)
      .then(({ data }) => {
        if (data.errorMsg) {
          Alert("error", "Invalid credentials");
          return;
        }
        if (data.wallet) localStorage.setItem("beta_account", data.wallet);
        if (data.common) localStorage.setItem("beta_account", "common");
        setState(true);
        return data;
      })
      .catch((e) => {
        Alert("error", "Something went wrong");
        return null;
      });

    return response;
  },

  async login(dto: ILoginDto) {
    const response = await api
      .post<IUserResponse>(API_ROUTES.LOGIN, dto)
      .then(({ data }) => {
        if (data.success === false) Alert("error", "Something went wrong");
        if (data.token) localStorage.setItem("auth_key", data.token);
        return data;
      })
      .catch((e) => {
        console.log(e);
        return null;
      });

    return response;
  },

  async refresh() {
    const response = await authApi
      .post<IRefreshResponse>(API_ROUTES.REFRESH)
      .then(({ data }) => {
        if (data.token) localStorage.setItem("auth_key", data.token);
        return data;
      })
      .catch((e) => {
        console.log(e);
        return null;
      });

    return response;
  },

  async logout() {
    const response = await authApi
      .get<IUserResponse>(API_ROUTES.LOGOUT)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        console.log(e);
        return null;
      });

    return response;
  },

  async getData() {
    const response = await newAuthApi
      .post<IInitResponse>(API_ROUTES.GET_DATA, { platform: "web" })
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        console.log(e);
        return {
          user: null,
          races: null,
          contracts: null,
        };
      });

    return response;
  },

  async getBalance() {
    const response = await newAuthApi
      .get<IUserBalance>(API_ROUTES.GET_BALANCE_DATA)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        console.log(e);
      });

    return response;
  },
};
