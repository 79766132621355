import React from "react";
import Circle from "icons/Circle";
import Button from "../UI/Button";
import styles from "styles/components/sidebar.module.scss";
import { useAccount, useDisconnect } from "wagmi";
import Alert from "utils/alert";
import { UserApi } from "utils/api/User";
import { action } from "common/bridge";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import { selectUser } from "redux/selectors/userSelector";
import { setProfile, setUser } from "redux/reducers/UserSlice";

const UserInfo: React.FC = () => {
  const { user } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const { address } = useAccount();
  const { disconnect } = useDisconnect();

  const handleDisconnect = async () => {
    await UserApi.logout();
    action.post.disconnect();
    disconnect();
    Alert("success", "Successfully disconnected");
    localStorage.removeItem("auth_key");
    localStorage.removeItem("wallet");
    dispatch(setProfile(null));
    dispatch(setUser(null));
  };

  return (
    <div className={styles.sidebar__info_item}>
      <div className={styles.sidebar__info_left}>
        <Circle />
        <div>
          <span className={styles.sidebar__info_title}>Account</span>
          <span className={styles.sidebar__info_data}>
            {user &&
              address &&
              address.substring(0, 4) + "..." + address.slice(-4)}
          </span>
        </div>
      </div>

      <Button callback={handleDisconnect} label={"Disconnect"} />

      {/* {user && (
        <div className={styles.sidebar__balance}>
          Balance: <Balance /> USDT
        </div>
      )} */}
    </div>
  );
};

export default UserInfo;
