import React, { useCallback, useRef, useState, useEffect, memo } from "react";
import { GotoType, useBridge } from "common/bridge";
import styles from "styles/components/sidebar.module.scss";
import Arrow from "icons/Arrow";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import { selectUser } from "redux/selectors/userSelector";
import { fetchBlockchainData } from "redux/asyncActions/fetchBlockChainData";

const Navigation: React.FC = memo(() => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectUser);

  const arrowRef = useRef() as any;

  const [navList, setNavList] = useState([
    {
      label: "Main",
      description: "Here you can buy a car and all the details for them.",
      active: true,
    },

    {
      label: "Shop",
      description: "Here you can buy a car and all the details for them.",
      active: false,
    },

    {
      label: "Garage",
      description: "Here you can buy a car and all the details for them.",
      active: false,
    },

    {
      label: "Race",
      description: "Here you can buy a car and all the details for them.",
      active: false,
    },

    {
      label: "Ratings",
      description: "Here you can buy a car and all the details for them.",
      active: false,
    },

    {
      label: "How to play",
      description: "Here you can buy a car and all the details for them.",
      active: false,
    },
  ]);
  const [activeElement, setActiveElement] = useState(0);

  const onGoto = useCallback((type: GotoType) => {
    const id = ["main", "shop", "garage", "race", "rating", "rules"].indexOf(
      type
    );

    let newArr = [...navList];

    const goto = [
      useBridge.actions.gotoMain,
      useBridge.actions.gotoShop,
      useBridge.actions.gotoGarage,
      useBridge.actions.gotoRace,
      useBridge.actions.gotoRating,
      useBridge.actions.gotoRules,
    ][id];
    goto && goto();

    newArr.forEach((item, key) => {
      if (id === key) {
        item.active = true;
        setActiveElement(id);
      } else {
        item.active = false;
      }
    });

    setNavList(newArr);
  }, []);

  useBridge("", "", undefined, onGoto);

  const toggleNavState = useCallback(
    (e: React.MouseEvent<HTMLLIElement>, id: number) => {
      let newArr = [...navList];

      arrowRef.current.style.top = e.currentTarget.offsetTop + "px";

      const goto = [
        useBridge.actions.gotoMain,
        useBridge.actions.gotoShop,
        useBridge.actions.gotoGarage,
        useBridge.actions.gotoRace,
        useBridge.actions.gotoRating,
        useBridge.actions.gotoRules,
      ][id];
      goto && goto();

      newArr.forEach((item, key) => {
        if (id === key) {
          item.active = true;
          setActiveElement(id);
        } else {
          item.active = false;
        }
      });

      setNavList(newArr);
    },
    [arrowRef]
  );

  useEffect(() => {
    if (user) {
      navList.forEach((item) => {
        if (item.label === "Main" && item.active) {
          dispatch(fetchBlockchainData());
        }
      });
    }
  }, [navList]);

  const handleMouseOver = (key: number) => {
    if (activeElement > key)
      arrowRef.current.style.top =
        Number(arrowRef.current.style.top.replace("px", "")) + 42 + "px";
  };

  const handleMouseLeave = (key: number) => {
    if (activeElement > key)
      arrowRef.current.style.top =
        Number(arrowRef.current.style.top.replace("px", "")) - 42 + "px";
  };

  return (
    <nav className={styles.sidebar__nav}>
      <ul className={styles.sidebar__nav_list}>
        <div className={styles.sidebar__nav_arrow} ref={arrowRef}>
          <Arrow />
        </div>

        {navList.map((item, key) => {
          return (
            <li
              onMouseLeave={() => handleMouseLeave(key)}
              onMouseOver={() => handleMouseOver(key)}
              onClick={(e) => toggleNavState(e, key)}
              className={`${styles.sidebar__nav_item} ${
                item.active ? styles["sidebar__nav_item--active"] : ""
              }`}
              key={key}
            >
              <a>{item.label}</a>

              <p>{item.description}</p>
            </li>
          );
        })}
      </ul>
    </nav>
  );
});

export default Navigation;
