import { createAsyncThunk } from "@reduxjs/toolkit";
import { ProfileApi } from "utils/api/Profile";

export const fetchProfileData = createAsyncThunk(
  "user/fetchProfileData",
  async () => {
    const response = ProfileApi.getState();

    return response;
  }
);
