import { action } from "common/bridge";
import Alert from "./alert";
import { baseTransactionProps } from "./transactionSucess";

interface transactinErrorProps extends baseTransactionProps {
  isTransactionError: boolean;
  isError: boolean;
  isConfigError: boolean;
  configError: Error | null;
  reset?: () => void;
}

export const transactinError = async ({
  setState,
  type = "number",
  isTransactionError,
  isConfigError,
  isError,
  configError,
  reset,
}: transactinErrorProps) => {
  if (
    !isTransactionError &&
    configError?.message.includes("ERC20: insufficient allowance")
  ) {
    return;
  }

  if (isError || isTransactionError || isConfigError) {
    // handling 0 carbox counter error
    if (
      //@ts-ignore
      configError?.cause.data.errorName === "MintZeroQuantity" ||
      //@ts-ignore
      configError?.cause.data.args[0] === "Only provider"
    ) {
      action.post.purchaseApproved(true);
      return;
    }

    if (setState) {
      switch (type) {
        case "array":
          setState([]);
          break;
        default:
          setState(0);
          break;
      }
    }
    //@ts-ignore
    const shortError = configError?.shortMessage;

    if (shortError?.includes("SecretGameName:"))
      Alert("error", shortError?.split("SecretGameName:")[1]);
    else Alert("error", "Transaction failed");

    reset && reset();
    action.post.purchaseApproved(false);
  }
};
