import React, { useState, useEffect, useCallback, useRef } from "react";
import { ToastContainer } from "react-toastify";
import { UpdateRace, action, useBridge } from "common/bridge";
import { IRace } from "interfaces/Race";
import { RaceApi } from "utils/api/Race";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import { selectUser } from "redux/selectors/userSelector";
import { fetchBlockchainData } from "redux/asyncActions/fetchBlockChainData";
import { UserApi } from "utils/api/User";

interface LayoutProps {
  children: React.ReactNode;
}

const UPDATE_RACE_INTERVAL = 10000;

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { user, races, blockchainData } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const [qualRace, setQualRace] = useState<IRace | null>(null);

  const isQualFinishedRef = useRef(false);

  useEffect(() => {
    if (user) dispatch(fetchBlockchainData());
  }, [user]);

  const updateRaces = useCallback((currentRace: IRace) => {
    const formattedRaces = [];

    var qualificationRace: UpdateRace = {
      carIds: currentRace!.carIds,
      finishDateTime: currentRace!.finishedAt || 0,
      fund: currentRace!.fund,
      id: currentRace!.id,
      prizes: currentRace!.winnersPrizes,
      startDateTime: currentRace!.startedAt,
      status: currentRace!.status,
      type: "main",
      resultCarIds: currentRace!.places,
    };

    formattedRaces.push(qualificationRace);

    action.post.updateRaces(formattedRaces);
  }, []);

  const intervalCallback = async () => {
    if (races!.qualification.current && !isQualFinishedRef.current) {
      const response = await RaceApi.getRaceData(
        races!.qualification.current.id
      );

      if (!isQualFinishedRef.current) setQualRace(response.race);
      if (response.race.status === "finished") {
        isQualFinishedRef.current = true;
        UserApi.getBalance().then((res) => {
          if (res) {
            action.post.updateUnclaimed(res.unclaimed);
          }
        });
      }
    }
  };

  useEffect(() => {
    if (blockchainData?.balance) {
      action.post.updateUnclaimed(blockchainData.balance.unclaimed);
    }
  }, [blockchainData?.balance]);

  useEffect(() => {
    if (races) {
      setQualRace(races.qualification.current);
      setInterval(intervalCallback, UPDATE_RACE_INTERVAL);
    }

    if (!races?.qualification.current) setQualRace(null);
  }, [races]);

  useEffect(() => {
    if (qualRace) {
      updateRaces(qualRace);
    }
  }, [qualRace]);

  useBridge("game", `Bearer ${localStorage.getItem("auth_key")}`);

  return (
    <div className="wrapper">
      <ToastContainer />

      {children}
    </div>
  );
};

export default Layout;
