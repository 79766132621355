import React from "react";

const Arrow: React.FC = (props) => {
  return (
    <svg
      width={13}
      height={14}
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_52_20)">
        <path
          d="M6.00247 0.00357056L13 6.99999L6.00247 13.9964H0L6.99753 6.99999L0 0.00357056H6.00247Z"
          fill="url(#paint0_linear_52_20)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_52_20"
          x1="6.5"
          y1="0.00357056"
          x2="6.5"
          y2="13.9964"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7854" />
          <stop offset={1} stopColor="#E73B4B" />
        </linearGradient>
        <clipPath id="clip0_52_20">
          <rect width={13} height={14} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Arrow;
