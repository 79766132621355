import Logo from "icons/Logo";
import Discord from "icons/social/Discord";
import Ship from "icons/social/Ship";
import Telegram from "icons/social/Telegram";
import Twitter from "icons/social/Twitter";
import React, { useEffect, useState } from "react";
import styles from "styles/components/sidebar.module.scss";
import scrollbarStyles from "styles/components/custom-scrollbar.module.scss";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import { connectWagmiWallet } from "utils/connectWagmiWallet";
import LootboxInfo from "./Sidebar/LootboxInfo";
import EnergyInfo from "./Sidebar/EnergyInfo";
import Navigation from "./Sidebar/Navigation";
import UserInfo from "./Sidebar/UserInfo";
import action from "common/bridge/action";
import MuteMusic from "icons/MuteMusic";
import MuteSound from "icons/MuteSound";
import MintCarBox from "./MintCarBox";
import Connect from "./Sidebar/Connect";
import Overlay from "./Sidebar/Overlay";
import { UserApi } from "utils/api/User";
import PrimaryRace from "./PrimaryRace";
import OpenCarBox from "./OpenCarBox";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import { setUser } from "redux/reducers/UserSlice";
import { selectUser } from "redux/selectors/userSelector";
import { fetchProfileData } from "redux/asyncActions/fetchProfileData";
import ClaimReward from "./ClaimReward";

const socialList = [
  {
    icon: <Discord />,
    href: "#",
  },

  {
    icon: <Ship />,
    href: "#",
  },

  {
    icon: <Telegram />,
    href: "#",
  },

  {
    icon: <Twitter />,
    href: "#",
  },
];

const Sidebar: React.FC = () => {
  const dispatch = useAppDispatch();
  const { user, blockchainData } = useAppSelector(selectUser);

  const { disconnect } = useDisconnect();
  const { isConnected, address } = useAccount();
  const { connect, connectors } = useConnect();

  const [disabled, setDisabled] = useState(true);
  const [muttedSounds, setMuttedSounds] = useState(true);
  const [muttedMusic, setMuttedMusic] = useState(true);

  const handleConnect = async () => {
    const user = await connectWagmiWallet(
      address ? address : (localStorage.getItem("wallet") as `0x${string}`)
    );

    if (user) {
      dispatch(setUser(user?.user));
    } else {
      disconnect();
    }
  };

  useEffect(() => {
    if (user) {
      const callback = () => {
        UserApi.refresh();
      };
      var interval = setInterval(callback, 900000);
    }

    return () => clearInterval(interval);
  }, [user]);

  useEffect(() => {
    if (user) {
      dispatch(fetchProfileData());

      if (!isConnected) {
        const metamask = connectors[0];
        const walletConnect = connectors[1];

        try {
          connect({ connector: metamask });
        } catch {
          connect({ connector: walletConnect });
        }
      }
    }
  }, [user]);

  useEffect(() => {
    if (
      isConnected ||
      (localStorage.getItem("wallet") && localStorage.getItem("auth_key"))
    ) {
      handleConnect();
    }
  }, [isConnected, localStorage]);

  useEffect(() => {
    if (isConnected && user) {
      action.post.connect();
    }
  }, [isConnected, user]);

  useEffect(() => {
    action.post.muteSounds(muttedSounds);
  }, [muttedSounds]);

  useEffect(() => {
    action.post.muteMusic(muttedMusic);
  }, [muttedMusic]);

  return (
    <aside
      className={`${styles.sidebar} ${scrollbarStyles.custom_scrollbar} ${
        disabled ? styles["sidebar--disabled"] : ""
      }`}
    >
      <div className={styles.sidebar__header}>
        <div
          onClick={() => {
            setMuttedMusic(!muttedMusic);
          }}
          className={`${styles.sidebar__mute_music} ${
            muttedMusic ? styles["sidebar__mute_music--active"] : ""
          }`}
        >
          <MuteMusic />
        </div>

        <div
          onClick={() => {
            setMuttedSounds(!muttedSounds);
          }}
          className={`${styles.sidebar__mute_sound} ${
            muttedSounds ? styles["sidebar__mute_sound--active"] : ""
          }`}
        >
          <MuteSound />
        </div>

        <img src="/img/sidebar_header.gif" alt="Animated skull image" />
      </div>

      <div className={styles.sidebar__content}>
        <div className={styles.sidebar__logo}>
          <div className={styles.sidebar__top}>
            <Logo />
            <div>
              <h4 className={styles.sidebar__title}>Meta Racing</h4>

              <p>
                <span>Drive •</span>
                <span>Dare •</span>
                <span>Win</span>
              </p>
            </div>
          </div>
        </div>

        <Navigation />

        <div className={styles.sidebar__wrapper}>
          <div className={styles.sidebar__info}>
            {isConnected && blockchainData ? (
              <>
                <UserInfo />

                <EnergyInfo />

                <LootboxInfo />

                <MintCarBox />

                <PrimaryRace />

                <OpenCarBox />

                <ClaimReward />
              </>
            ) : (
              <Connect />
            )}
          </div>

          <div className={styles.sidebar__social}>
            <h4 className={styles.sidebar__social_title}>Social media</h4>

            <div className={styles.sidebar__social_row}>
              {socialList.map((item, key) => (
                <a href={item.href} key={key}>
                  {item.icon}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Overlay disabled={disabled} setDisabled={setDisabled} />
    </aside>
  );
};

export default Sidebar;
