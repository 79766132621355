import { IPopup } from "redux/models/PopupState";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type PopupState = IPopup;

const initialState: PopupState = {
  content: null,
  isOpen: false,
};

const popupSlice = createSlice({
  name: "popup",
  initialState,
  reducers: {
    setContent(state, action: PayloadAction<React.ReactNode>) {
      state.content = action.payload;
    },

    setIsOpen(state, action: PayloadAction<boolean>) {
      state.isOpen = action.payload;
    },
  },
});

export const { setContent, setIsOpen } = popupSlice.actions;
export default popupSlice.reducer;
