import React from "react";
import styles from "styles/components/primary-button.module.scss";
import { Link } from "react-router-dom";

interface PrimaryButtonProps {
  disabled?: boolean;
  label: string;
  href?: string;
  callback?: () => void;
  className?: string;
  fullwidth?: boolean;
  small?: boolean;
  icon?: React.ReactNode;
  centered?: boolean;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  disabled,
  label,
  href,
  callback,
  className = "",
  fullwidth,
  small,
  icon,
  centered,
}) => {
  const classList = `${styles.primary_button} ${className} ${
    fullwidth ? styles["primary_button--fullwidth"] : ""
  } ${small ? styles["primary_button--small"] : ""} ${
    centered ? styles["primary_button--centered"] : ""
  } ${disabled ? styles["primary_button--disabled"] : ""}`;

  return (
    <>
      {href ? (
        <Link to={href} className={classList}>
          {icon && icon}
          {label}
        </Link>
      ) : (
        <button className={classList} onClick={callback} disabled={disabled}>
          {icon && icon}
          {label}
        </button>
      )}
    </>
  );
};

export default PrimaryButton;
