import React from "react";
import { useAppSelector } from "redux/hooks/redux";
import { selectUser } from "redux/selectors/userSelector";

const Balance: React.FC = () => {
  const { blockchainData } = useAppSelector(selectUser);

  // action.post.updateBalance(Number(data?.formatted));

  return <>{blockchainData?.balance.usdt}</>;
};

export default Balance;
