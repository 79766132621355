import { useEffect } from "react";
import action from "./action";
import message from "./message";
import {
  CostValue,
  EventType,
  GotoType,
  RarityRecord,
  RarityValue,
} from "./types";
//import axios from "./fakeAxios";
import axios from "axios";
import { API_URL } from "./constants";
import callbacks from "./callbacks";
import { getConfig, setToken } from "./token";

let _observer: MutationObserver | undefined;
let _constInited = false;
const _cost: CostValue = {
  lootbox: 0,
  carbox: 0,
};
let _lootInited = false;
let _rarityInited = false;
const _rarity: RarityValue = {
  details: 0,
  stickers: 0,
  lootbox: {
    common: 0,
    uncommon: 0,
    rare: 0,
    epic: 0,
    legendary: 0,
  },
  carbox: {
    common: 0,
    uncommon: 0,
    rare: 0,
    epic: 0,
    legendary: 0,
  },
};
let _removeListenArr: Function[] = [];

let _offsetTime = 0;
axios
  .get("https://worldtimeapi.org/api/timezone/Europe/London")
  .then((responce) => {
    if (responce?.data?.unixtime) {
      _offsetTime = Date.now() - responce?.data?.unixtime * 1000;
    }
  });

function startListen() {
  _removeListenArr.forEach((r) => r());
  _removeListenArr = [
    message.listen(EventType.EVENT_INTERFACE_ENABLE, () => {
      callbacks.call("onEnable", true);
    }),

    message.listen(EventType.EVENT_INTERFACE_DISABLE, () => {
      callbacks.call("onEnable", false);
    }),

    message.listen(EventType.EVENT_GET_STATE, () => {
      action.setIsReady(true);

      action.post.updateOffsetTime(_offsetTime);
      action.post.updateBalance();
      action.post.updateRaces();

      if (!_constInited) {
        axios
          .get(API_URL + "pages/shop")
          .then((response) => {
            const data = response?.data as {
              name: "lootbox" | "carbox";
              cost: number;
            }[];
            if (data) {
              _cost.lootbox = data.find((d) => d.name === "lootbox")?.cost || 0;
              _cost.carbox = data.find((d) => d.name === "carbox")?.cost || 0;
              _constInited = true;
              action.post.updateCost(_cost);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }

      if (!_rarityInited) {
        axios
          .get(API_URL + "pages/loot")
          .then((response) => {
            const data = response?.data as { Detail: number; Sticker: number };
            if (data) {
              _rarity.details = data.Detail;
              _rarity.stickers = data.Sticker;
              _lootInited = true;
              if (_rarityInited && _lootInited) {
                action.post.updateRarity(_rarity);
              }
            }
          })
          .catch((error) => {
            console.log(error);
            _lootInited = true;
          });

        axios
          .get(API_URL + "pages/rarity")
          .then((response) => {
            const data = response?.data as {
              name: "Common" | "Uncommon" | "Rare" | "Epic" | "Legendary";
              carbox: number;
              lootbox: number;
            }[];
            if (data) {
              for (const d of data) {
                _rarity.lootbox[d.name.toLowerCase() as keyof RarityRecord] =
                  d?.lootbox || 0;
                _rarity.carbox[d.name.toLowerCase() as keyof RarityRecord] =
                  d?.carbox || 0;
              }

              _rarityInited = true;
              if (_rarityInited && _lootInited) {
                action.post.updateRarity(_rarity);
              }
            }
          })
          .catch((error) => {
            console.log(error);
            _rarityInited = true;
          });
      }

      const config = getConfig();
      if (config) {
        axios
          .get(API_URL + "state/get", config)
          .then((response) => {
            action.post.responseEvent(
              EventType.EVENT_GET_STATE,
              response?.data
            );
          })
          .catch((error) => {
            console.log(error);
            action.post.responseEvent(EventType.EVENT_GET_STATE);
            action.post.error();
          });
      } else {
        action.post.responseEvent(EventType.EVENT_GET_STATE, {
          cars: [],
          carBoxes: [],
        });
      }
    }),

    message.listen(EventType.EVENT_PART_VIEWED, (result) => {
      axios
        .post(
          API_URL + "part/show",
          {
            id: result.data,
          },
          getConfig()
        )
        .then((response) => {
          action.post.responseEvent(
            EventType.EVENT_PART_VIEWED,
            response?.data
          );
        })
        .catch((error) => {
          console.log(error);
          action.post.responseEvent(EventType.EVENT_PART_VIEWED);
          action.post.error();
        });
    }),

    message.listen(EventType.EVENT_GET_RATING, () => {
      const config = getConfig();
      axios
        .get(API_URL + (config ? "state/rating" : "pages/ratings"), config)
        .then((response) => {
          action.post.responseEvent(EventType.EVENT_GET_RATING, response?.data);
        })
        .catch((error) => {
          console.log(error);
          action.post.responseEvent(EventType.EVENT_GET_RATING);
          action.post.error();
        });
    }),

    message.listen<number>(EventType.EVENT_OPEN_LOOTBOX, (result) => {
      axios
        .post(
          API_URL + "lootbox/open",
          {
            id: result.data,
          },
          getConfig()
        )
        .then((response) => {
          console.log(response?.data);
          action.post.responseEvent(
            EventType.EVENT_OPEN_LOOTBOX,
            response?.data
          );
          callbacks.call("onOpenLootbox");
        })
        .catch((error) => {
          console.log(error);
          action.post.responseEvent(EventType.EVENT_OPEN_LOOTBOX);
          action.post.error();
        });
    }),

    message.listen<number>(EventType.EVENT_OPEN_CARBOX, (result) => {
      callbacks.call("onOpenCarbox", result.data || 0);

      // axios
      //   .post(
      //     API_URL + "carbox/open",
      //     {
      //       id: result.data,
      //     },
      //     getConfig()
      //   )
      //   .then((response) => {
      //     console.log(response?.data);
      //     action.post.responseEvent(
      //       EventType.EVENT_OPEN_CARBOX,
      //       response?.data
      //     );
      //     callbacks.call("onOpenCarbox");
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //     action.post.responseEvent(EventType.EVENT_OPEN_CARBOX);
      //     action.post.error();
      //   });
    }),

    message.listen<{ car_id: number; parts: number[] }>(
      EventType.EVENT_SET_PARTS,
      (result) => {
        axios
          .post(API_URL + "state/set-parts", result.data, getConfig())
          .then((response) => {
            console.log(response?.data);
            action.post.responseEvent(
              EventType.EVENT_SET_PARTS,
              response?.data
            );
          })
          .catch((error) => {
            console.log(error);
            action.post.responseEvent(EventType.EVENT_SET_PARTS);
            action.post.error();
          });
      }
    ),

    message.listen(EventType.EVENT_GOTO_MAIN, () => {
      callbacks.call("onGoto", "main");
    }),
    message.listen(EventType.EVENT_GOTO_GARAGE, () => {
      callbacks.call("onGoto", "garage");
    }),
    message.listen(EventType.EVENT_GOTO_RACE, () => {
      callbacks.call("onGoto", "race");
    }),
    message.listen(EventType.EVENT_GOTO_SHOP, () => {
      callbacks.call("onGoto", "shop");
    }),
    message.listen(EventType.EVENT_GOTO_RULES, () => {
      callbacks.call("onGoto", "rules");
    }),

    message.listen<number>(EventType.EVENT_BUY_CARBOX_ACCEPT, (result) => {
      callbacks.call("onAcceptBuyCarbox", result.data || 0);
    }),
    message.listen<number>(EventType.EVENT_BUY_CARBOX_BUY, (result) => {
      callbacks.call("onBuyCarbox", result.data || 0);
    }),
    message.listen<number>(EventType.EVENT_BUY_LOOTBOX_ACCEPT, (result) => {
      callbacks.call("onAcceptBuyLootbox", result.data || 0);
    }),
    message.listen<number>(EventType.EVENT_BUY_LOOTBOX_BUY, (result) => {
      callbacks.call("onBuyLootbox", result.data || 0);
    }),

    message.listen<number>(
      EventType.EVENT_CHANGE_USDT_TO_MGT_ACCEPT,
      (result) => {
        callbacks.call("onAcceptChangeUSDTToMGT", result.data || 0);
      }
    ),

    message.listen<number>(
      EventType.EVENT_CHANGE_MGT_TO_USDT_ACCEPT,
      (result) => {
        callbacks.call("onAcceptChangeMGTToUSDT", result.data || 0);
      }
    ),
    message.listen<number>(
      EventType.EVENT_CHANGE_USDT_TO_MGT_SWAP,
      (result) => {
        callbacks.call("onSwapChangeUSDTToMGT", result.data || 0);
      }
    ),

    message.listen<number>(
      EventType.EVENT_CHANGE_MGT_TO_USDT_SWAP,
      (result) => {
        callbacks.call("onSwapChangeMGTToUSDT", result.data || 0);
      }
    ),

    message.listen<number[]>(EventType.EVENT_JOIN_RACE_GARAGE, (result) => {
      callbacks.call("onJoinRaceGarage", result.data || []);
    }),

    message.listen<number[]>(EventType.EVENT_JOIN_RACE_PRIMARY, (result) => {
      callbacks.call("onJoinRacePrimary", result.data || []);
    }),

    message.listen<number>(EventType.EVENT_WITHDRAW_REWARD, (result) => {
      callbacks.call("onWithdrawReward", result.data || []);
    }),
  ];
}

let _prevIframeId = "";
function init(iframeId: string) {
  if (!iframeId || _prevIframeId === iframeId) {
    return;
  }
  _prevIframeId = iframeId;
  _observer?.disconnect();
  _observer = new MutationObserver((_, obs) => {
    const iframe = document.getElementById(iframeId) as HTMLIFrameElement;
    if (iframe) {
      action.setIframeReceiver(iframe);
      obs.disconnect();
      startListen();
      return;
    }
  });
  _observer.observe(document, {
    childList: true,
    subtree: true,
  });
}

function useBridge(
  iframeId = "",
  token = "",
  onEnable?: (enable: boolean) => void,
  onGoto?: (type: GotoType) => void,
  onBuyLootbox?: (amount: number) => void,
  onBuyCarbox?: (amount: number) => void,
  onOpenLootbox?: () => void,
  onOpenCarbox?: () => void
) {
  if (token) {
    setToken(token);
  }

  useEffect(() => {
    callbacks.add("onEnable", onEnable);
    callbacks.add("onGoto", onGoto);
    callbacks.add("onBuyLootbox", onBuyLootbox);
    callbacks.add("onBuyCarbox", onBuyCarbox);
    callbacks.add("onOpenLootbox", onOpenLootbox);
    callbacks.add("onOpenCarbox", onOpenCarbox);
  }, [
    onEnable,
    onGoto,
    onBuyLootbox,
    onBuyCarbox,
    onOpenLootbox,
    onOpenCarbox,
  ]);

  useEffect(() => {
    init(iframeId);
  }, [iframeId]);

  return action.post;
}

useBridge.actions = action.post;

export default useBridge;
