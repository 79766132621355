import Button from "components/UI/Button";
import React from "react";
import styles from "styles/components/sidebar.module.scss";
import ConnectWallet from "../Popup/ConnectWallet";
import { useConnect } from "wagmi";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import { selectUser } from "redux/selectors/userSelector";
import { setContent, setIsOpen } from "redux/reducers/PopupSlice";

const Connect: React.FC = () => {
  const { user } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const { connect, connectors } = useConnect();

  const handleOpenModal = () => {
    if (user) {
      const metamask = connectors[0];
      const walletConnect = connectors[1];

      try {
        connect({ connector: metamask });
      } catch {
        connect({ connector: walletConnect });
      }
    } else {
      dispatch(setContent(<ConnectWallet />));
      dispatch(setIsOpen(true));
    }
  };

  return (
    <div className={styles.sidebar__connect}>
      <p>
        Some kind of informational text that explains why you need to connect a
        wallet.
      </p>

      <Button label="Connect wallet" big callback={handleOpenModal} />
    </div>
  );
};

export default Connect;
