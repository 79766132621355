import React from "react";

const Success: React.FC = () => {
  return (
    <svg
      width={56}
      height={62}
      viewBox="0 0 56 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.6126 1.53716L42 6.36149L50.3874 11.1858C53.9505 13.2354 56 16.7984 56 20.8975V30.5777V40.2579C56 44.3885 53.9505 47.92 50.3874 49.9696L42 54.7939L33.6126 59.6182C30.0495 61.6678 25.9505 61.6678 22.3874 59.6182L14 54.7939L5.61261 49.9696C2.04955 47.92 0 44.357 0 40.2579V30.5777V20.8975C0 16.7669 2.04955 13.2354 5.61261 11.1858L14 6.36149L22.3874 1.53716C25.9505 -0.512387 30.0495 -0.512387 33.6126 1.53716ZM22.3874 7.7804L11.036 14.339C7.47297 16.3885 5.42342 19.9516 5.42342 24.0507V37.1678C5.42342 41.2984 7.47297 44.83 11.036 46.8795L22.3874 53.4381C25.9505 55.4876 30.0495 55.4876 33.6126 53.4381L44.964 46.8795C48.527 44.83 50.5766 41.2669 50.5766 37.1678V24.0507C50.5766 19.92 48.527 16.3885 44.964 14.339L33.6126 7.7804C30.0495 5.73086 25.9505 5.73086 22.3874 7.7804Z"
        fill="white"
      />
      <path
        d="M42.5223 23.6749L38.0387 19.1913L18.8441 38.3859L23.3277 42.8695L42.5223 23.6749Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.09 27.6768L12.6125 32.1543L23.3333 42.875L27.8107 38.3975L17.09 27.6768Z"
        fill="white"
      />
    </svg>
  );
};

export default Success;
