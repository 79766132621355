import React, { useCallback } from "react";
import MintLootbox from "../MintLootbox";
import styles from "styles/components/sidebar.module.scss";
import Rhombus from "icons/Rhombus";
import Button from "components/UI/Button";
import { useBridge, callbacks } from "common/bridge";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import { selectUser } from "redux/selectors/userSelector";
import { fetchProfileData } from "redux/asyncActions/fetchProfileData";

const LootboxInfo: React.FC = () => {
  const { profile } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const { showLootboxPopup } = useBridge();

  const openLootbox = () => {
    showLootboxPopup();
  };

  const handleFetch = useCallback(() => {
    dispatch(fetchProfileData());
  }, []);

  callbacks.add("onOpenLootbox", handleFetch);

  return (
    <div className={styles.sidebar__info_item}>
      <div className={styles.sidebar__info_left}>
        <Rhombus />
        <div>
          <span className={styles.sidebar__info_title}>Loot boxes</span>
          <span className={styles.sidebar__info_data}>
            {profile && profile.lootBoxes.length}
          </span>
        </div>
      </div>

      <div className={styles.sidebar__info_buttons}>
        {profile && profile.lootBoxes.length > 0 && (
          <Button label="Open" callback={openLootbox} />
        )}
        <MintLootbox />
      </div>
    </div>
  );
};

export default LootboxInfo;
