import React, { useState, useEffect, useCallback, useRef, memo } from "react";
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useAccount,
  useContractRead,
} from "wagmi";
import { NFTApi } from "utils/api/NFT";
import { callbacks } from "common/bridge";
import useDebounce from "utils/useDebounce";
import { parseEther } from "viem";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import { selectUser } from "redux/selectors/userSelector";
import { transactionSuccess } from "utils/transactionSucess";
import { transactinError } from "utils/transactionError";
import { approveTransactionError } from "utils/approveTransactionError";

const CARBOX_PRICE = 100;

const MintCarBox: React.FC = memo(() => {
  const dispatch = useAppDispatch();
  const { abi } = useAppSelector(selectUser);

  const { address } = useAccount();

  const [amount, setAmount] = useState<number>(0);
  const debouncedAmount = useDebounce(amount, 0);

  // Contract request recreates twice. this ref created to send only one contract
  const preventContractDuplicate = useRef<boolean>(false);
  const preventAllowanceDuplicate = useRef(false);

  const {
    config,
    isError: isConfigError,
    error: configError,
  } = usePrepareContractWrite({
    address: abi!.main.address,
    abi: abi!.main.abi,
    args: [debouncedAmount],
    functionName: "buyCarBox",
    enabled: debouncedAmount > 0,
  });

  const { write, data, isError, reset } = useContractWrite(config);

  const {
    isSuccess,
    isError: isTransactionError,
    data: response,
  } = useWaitForTransaction({
    hash: data?.hash,
    confirmations: 3,
  });

  const { config: approveConfig, isError: isApproveConfigError } =
    usePrepareContractWrite({
      address: abi!.mgt.address,
      abi: abi!.usdt.abi,
      args: [
        abi!.main.address,
        parseEther(`${debouncedAmount * CARBOX_PRICE}`),
      ],
      functionName: "approve",
      enabled: Boolean(debouncedAmount),
    });

  const { data: allowance, refetch: refetchAllowance } = useContractRead({
    address: abi!.mgt.address,
    abi: abi!.usdt.abi,
    args: [address, abi!.main.address],
    functionName: "allowance",
  });

  const {
    write: approveWrite,
    data: approveData,
    isError: isApproveError,
  } = useContractWrite(approveConfig);

  const { isSuccess: approveIsSuccess, isError: isApproveTransactionError } =
    useWaitForTransaction({
      hash: approveData?.hash,
    });

  const handleApprove = () => {
    approveWrite?.();
  };

  const handleMintCarbox = () => {
    write?.();
  };

  const onBuyCarBox = useCallback((_amount: number) => {
    setAmount(_amount);
  }, []);

  useEffect(() => {
    const formattedAmount = parseEther(`${CARBOX_PRICE * debouncedAmount}`);

    if (
      approveConfig.request &&
      preventAllowanceDuplicate.current &&
      (allowance as bigint) < formattedAmount
    ) {
      handleApprove();
      preventAllowanceDuplicate.current = false;
    } else {
      preventAllowanceDuplicate.current = true;
    }
  }, [approveConfig.request]);

  // wainig for update of contract arguments important!!!
  useEffect(() => {
    const allowanceCondition =
      (allowance as bigint) >= parseEther(`${CARBOX_PRICE * debouncedAmount}`);

    if (
      config.request &&
      debouncedAmount &&
      preventContractDuplicate.current &&
      allowanceCondition
    ) {
      handleMintCarbox();
      preventContractDuplicate.current = false;
    } else {
      preventContractDuplicate.current = true;
    }
  }, [config.request]);

  useEffect(() => {
    if (isSuccess) {
      const apiCall = () => {
        NFTApi.createCarBox();
        NFTApi.carBoxEvent({ data: response, amount: debouncedAmount });
      };

      transactionSuccess({
        setState: setAmount,
        refetchAllowance,
        apiCall,
        dispatch,
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (approveIsSuccess) {
      transactionSuccess({
        setState: setAmount,
        approve: true,
        refetchAllowance,
      });
    }
  }, [approveIsSuccess]);

  useEffect(() => {
    approveTransactionError({
      isApproveConfigError,
      isApproveError,
      isApproveTransactionError,
      setState: setAmount,
    });
  }, [isApproveTransactionError, isApproveError, isApproveConfigError]);

  useEffect(() => {
    transactinError({
      configError,
      isConfigError,
      isError,
      isTransactionError,
      reset,
      setState: setAmount,
    });
  }, [isError, isTransactionError, isConfigError]);

  callbacks.add("onBuyCarbox", onBuyCarBox);
  callbacks.add("onAcceptBuyCarbox", onBuyCarBox);

  return <></>;
});

export default MintCarBox;
