import { authApi as api, API_ROUTES } from ".";
import { IProfile } from "interfaces/Profile";

export const ProfileApi = {
  async getState() {
    const response = await api
      .get<IProfile>(API_ROUTES.GET_STATE)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        console.log(e);
        return null;
      });

    return response;
  },
};
