import { callbacks } from "common/bridge";
import React, { useState, useRef, useEffect, memo, useCallback } from "react";
import useDebounce from "utils/useDebounce";
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { action } from "common/bridge";
import Alert from "utils/alert";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import { selectUser } from "redux/selectors/userSelector";
import { fetchBlockchainData } from "redux/asyncActions/fetchBlockChainData";

const ClaimReward: React.FC = memo(() => {
  const dispatch = useAppDispatch();
  const { abi } = useAppSelector(selectUser);
  const [claiming, setClaiming] = useState<boolean>(false);
  const debouncedClaiming = useDebounce(claiming, 0);

  const preventDuplicate = useRef(false);

  const {
    config,
    error: configError,
    isError: isConfigError,
    refetch,
  } = usePrepareContractWrite({
    address: abi?.raceQualifications.address,
    abi: abi?.raceQualifications.abi,
    functionName: "claimReward",
    args: [],
    enabled: debouncedClaiming,
  });

  const { write, data: contractData, isError } = useContractWrite(config);

  const { isSuccess, isError: isTransactionError } = useWaitForTransaction({
    hash: contractData?.hash,
  });

  const onWithdraw = useCallback(async (_id: number) => {
    setClaiming(true);

    handleWithdraw();
  }, []);

  const handleWithdraw = () => {
    if (config.request) write?.();
  };

  // Waitig for update of contract arguments important!!!
  useEffect(() => {
    if (config.request && preventDuplicate.current && debouncedClaiming) {
      handleWithdraw();
      preventDuplicate.current = false;
    } else if (config.request) {
      preventDuplicate.current = true;
    }
  }, [config.request, debouncedClaiming]);

  useEffect(() => {
    const successCallback = async () => {
      action.post.purchaseApproved(true);
      Alert("success", "Transaction succeed");
      setClaiming(false);
      dispatch(fetchBlockchainData());
    };

    if (isSuccess) successCallback();
  }, [isSuccess]);

  useEffect(() => {
    if (isError || isTransactionError || isConfigError) {
      Alert("error", "Transaction failed");
      action.post.purchaseApproved(false);
      setClaiming(false);
    }
  }, [isError, isTransactionError, isConfigError]);

  callbacks.add("onWithdrawReward", onWithdraw);

  return <></>;
});

export default ClaimReward;
