import { useState, useCallback, useEffect, useRef, memo } from "react";
import MintEnergy from "../MintEnergy";
import styles from "styles/components/sidebar.module.scss";
import Balance from "components/Balance";
import Button from "components/UI/Button";
import {
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
  useContractRead,
} from "wagmi";
import MGT from "icons/MGT";
import { parseEther } from "viem";
import { callbacks, useBridge } from "common/bridge";
import { action } from "common/bridge";
import useDebounce from "utils/useDebounce";
import { NFTApi } from "utils/api/NFT";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import { selectUser } from "redux/selectors/userSelector";
import { transactinError } from "utils/transactionError";
import { approveTransactionError } from "utils/approveTransactionError";
import { transactionSuccess } from "utils/transactionSucess";

const EnergyInfo = memo(() => {
  const dispatch = useAppDispatch();
  const { abi, blockchainData } = useAppSelector(selectUser);

  const { address } = useAccount();

  const [amount, setAmount] = useState<number>(0);
  const debouncedAmount = useDebounce<number>(amount, 0);

  const { showChangerMGTToUSDT } = useBridge();
  const { isConnected } = useAccount();

  const {
    config,
    isError: isConfigError,
    error: configError,
  } = usePrepareContractWrite({
    address: abi!.main.address,
    abi: abi!.main.abi,
    args: [parseEther(`${debouncedAmount}`)],
    enabled: Boolean(debouncedAmount),
    functionName: "swapEnergy",
  });

  const { write, data, isError } = useContractWrite(config);

  const {
    isLoading,
    isError: isTransactionError,
    isSuccess,
    data: transactionData,
  } = useWaitForTransaction({
    hash: data?.hash,
  });

  const { config: approveConfig, isError: isApproveConfigError } =
    usePrepareContractWrite({
      address: abi!.mgt.address,
      abi: abi!.usdt.abi,
      args: [abi!.main.address, parseEther(`${debouncedAmount}`)],
      functionName: "approve",
      enabled: Boolean(debouncedAmount),
    });

  const { data: allowance, refetch: refetchAllowance } = useContractRead({
    address: abi!.mgt.address,
    abi: abi!.usdt.abi,
    args: [address, abi!.main.address],
    functionName: "allowance",
  });

  const {
    write: approveWrite,
    data: approveData,
    isError: isApproveError,
  } = useContractWrite(approveConfig);

  const { isSuccess: approveIsSuccess, isError: isApproveTransactionError } =
    useWaitForTransaction({
      hash: approveData?.hash,
    });

  const handleApprove = () => {
    approveWrite?.();
  };

  const onSwap = useCallback((_amount: number) => {
    setAmount(_amount);
  }, []);

  const handleWithdraw = () => {
    write?.();
  };

  const openSwapper = () => {
    showChangerMGTToUSDT(blockchainData!.balance.mgt);
  };

  // Waitig for update of contract arguments important!!!
  useEffect(() => {
    const allowanceCondition =
      (allowance as bigint) >= parseEther(`${debouncedAmount}`);

    if (config.request && debouncedAmount > 0 && allowanceCondition) {
      handleWithdraw();
    }
  }, [config.request]);

  useEffect(() => {
    const formattedAmount = parseEther(`${debouncedAmount}`);

    //@ts-ignore
    const isAllowanceError = configError?.shortMessage.includes(
      "ERC20: insufficient allowance"
    );

    if (
      (!isConfigError || isAllowanceError) &&
      approveConfig.request &&
      (allowance as bigint) < formattedAmount
    ) {
      handleApprove();
    }
  }, [approveConfig.request]);

  useEffect(() => {
    if (isSuccess) {
      const apiCall = () => {
        NFTApi.withdrawEnergyEvent({
          data: transactionData,
          amount: debouncedAmount,
        });
      };

      transactionSuccess({
        apiCall,
        setState: setAmount,
        refetchAllowance,
        dispatch,
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (blockchainData) {
      action.post.updateBalance(blockchainData!.balance.mgt);
    }
  }, [blockchainData]);

  useEffect(() => {
    if (approveIsSuccess) {
      transactionSuccess({
        approve: true,
        setState: setAmount,
        refetchAllowance,
      });
    }
  }, [approveIsSuccess]);

  useEffect(() => {
    approveTransactionError({
      isApproveConfigError,
      isApproveError,
      isApproveTransactionError,
      setState: setAmount,
    });
  }, [isApproveError, isApproveConfigError, isApproveTransactionError]);

  useEffect(() => {
    transactinError({
      configError,
      isConfigError,
      isError,
      isTransactionError,
      setState: setAmount,
    });
  }, [isError, isTransactionError, isConfigError]);

  callbacks.add("onAcceptChangeMGTToUSDT", onSwap);
  callbacks.add("onSwapChangeMGTToUSDT", onSwap);

  return (
    <div className={styles.sidebar__info_item}>
      <div className={styles.sidebar__info_left}>
        <MGT />
        <div>
          <span className={styles.sidebar__info_title}>
            {isConnected && (
              <>
                <Balance /> USDT
              </>
            )}
          </span>
          <span className={styles.sidebar__info_data}>
            {blockchainData && `${blockchainData!.balance.mgt} MGT`}
          </span>
        </div>
      </div>

      <div className={styles.sidebar__info_buttons}>
        <MintEnergy />

        <Button
          lessPadding
          label={isLoading ? "Swapping..." : `Withdraw`}
          callback={openSwapper}
        />
      </div>
    </div>
  );
});

export default EnergyInfo;
