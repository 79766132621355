import React from "react";
import styles from "styles/components/button.module.scss";

interface IButtonProps {
  label: string;
  callback: () => void;
  disabled?: boolean;
  lessPadding?: boolean;
  big?: boolean;
}

const Button: React.FC<IButtonProps> = ({
  callback,
  label,
  disabled,
  lessPadding,
  big,
}) => {
  return (
    <button
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault();
        callback();
      }}
      className={`${styles.primary_button} ${
        lessPadding ? styles["primary_button--less-padding"] : ""
      } ${big ? styles["primary_button--big"] : ""}`}
    >
      <span>{label}</span>
    </button>
  );
};

export default Button;
