import React from "react";

const Circle: React.FC = () => {
  return (
    <svg
      width={22}
      height={22}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3333 0.647034C14.1958 0.647034 16.7962 1.80516 18.6535 3.68439C20.5328 5.56362 21.6909 8.1421 21.6909 11.0046C21.6909 13.8672 20.5328 16.4675 18.6535 18.3249C16.7743 20.2041 14.1958 21.3623 11.3333 21.3623C8.47073 21.3623 5.8704 20.2041 4.01302 18.3249C2.13379 16.4457 0.97566 13.8672 0.97566 11.0046C0.97566 8.1421 2.13379 5.54177 4.01302 3.68439C5.89225 1.80516 8.47073 0.647034 11.3333 0.647034ZM15.4414 6.89656C14.3925 5.84769 12.9284 5.19215 11.3333 5.19215C9.73811 5.19215 8.27406 5.84769 7.22519 6.89656C6.17632 7.94544 5.52077 9.40949 5.52077 11.0046C5.52077 12.5998 6.17632 14.0639 7.22519 15.1127C8.27406 16.1616 9.73811 16.8171 11.3333 16.8171C12.9284 16.8171 14.3925 16.1616 15.4414 15.1127C16.4902 14.0639 17.1458 12.5998 17.1458 11.0046C17.1458 9.40949 16.4902 7.94544 15.4414 6.89656Z"
        fill="url(#paint0_linear_52_18)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_52_18"
          x1="11.3333"
          y1="0.647034"
          x2="11.3333"
          y2="21.3623"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7854" />
          <stop offset={1} stopColor="#E73B4B" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Circle;
