import { action } from "common/bridge";
import React from "react";
import Alert from "./alert";
import { fetchBlockchainData } from "redux/asyncActions/fetchBlockChainData";

export interface baseTransactionProps {
  type?: "number" | "array";
  setState?: React.Dispatch<React.SetStateAction<any>>;
}

interface transactionSuccessProps extends baseTransactionProps {
  apiCall?: () => void;
  refetchAllowance?: () => void;
  approve?: boolean;
  dispatch?: any;
}

export const transactionSuccess = async ({
  setState,
  apiCall,
  refetchAllowance,
  type = "number",
  approve = false,
  dispatch,
}: transactionSuccessProps) => {
  apiCall && (await apiCall());
  refetchAllowance && refetchAllowance();
  dispatch && dispatch(fetchBlockchainData());
  if (setState) {
    switch (type) {
      case "array":
        setState([]);
        break;
      default:
        setState(0);
        break;
    }
  }

  action.post.purchaseApproved(true);
  approve
    ? Alert("success", "Approve has been succeed")
    : Alert("success", "Transaction succeed");
};
