import axios from "axios";

export let baseURL = process.env.REACT_APP_API;
export let newBaseURL = process.env.REACT_APP_NEW_API;

export const api = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Max-Age": "3600",
  },
});

export const authApi = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Max-Age": "3600",
  },
});

export const newApi = axios.create({
  baseURL: newBaseURL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Max-Age": "3600",
  },
});

export const newAuthApi = axios.create({
  baseURL: newBaseURL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Max-Age": "3600",
  },
});

authApi.interceptors.request.use((config) => {
  // @ts-ignore
  config.headers.Authorization = `Bearer ${localStorage.getItem("auth_key")}`;

  return config;
});

newAuthApi.interceptors.request.use((config) => {
  // @ts-ignore
  config.headers.Authorization = `Bearer ${localStorage.getItem("auth_key")}`;

  return config;
});

export const API_ROUTES = {
  LOGIN: "auth/login/",
  BETA_LOGIN: "auth/panel/",
  LOGOUT: "auth/logout/",
  REFRESH: "auth/refresh-token/",
  GET_STATE: "state/get/",

  SEND_NFT: "state/get-token-cars/",

  CREATE_CARBOX: "carbox/user/",
  OPEN_CARBOX: "carbox/open/",

  CREATE_LOOTBOX: "lootbox/add/",

  ADD_ENERGY: "energy/add/",

  EVENT: "event/add/",

  JOIN_RACE: "race/enter/",

  GET_DATA: "init/",
  GET_QUALIFICATIONS_RACE_DATA: "race/qualification/",
  GET_BALANCE_DATA: "user/balance/",
};
