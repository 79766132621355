import Alert from "./alert";
import { action } from "common/bridge";
import { baseTransactionProps } from "./transactionSucess";

interface approveTransactionErrorProps extends baseTransactionProps {
  isApproveError: boolean;
  isApproveTransactionError: boolean;
  isApproveConfigError: boolean;
}

export const approveTransactionError = ({
  isApproveConfigError,
  isApproveError,
  isApproveTransactionError,
  setState,
  type = "number",
}: approveTransactionErrorProps) => {
  if (isApproveError || isApproveTransactionError || isApproveConfigError) {
    if (setState) {
      switch (type) {
        case "array":
          setState([]);
          break;
        default:
          setState(0);
          break;
      }
    }

    Alert("error", "Approve has been failed");
    action.post.purchaseApproved(false);
  }
};
